.App {
  padding: 30px;
}

.App .upper-menus,
.App .bubble-menus,
.App .floating-menus {
  background-color: #383838;
  padding: 0 6px;
}
.App .upper-menus button,
.App .bubble-menus button,
.App .floating-menus button {
  border: none;
  background: none;
  color: #f5f5f5;
  font-size: 21px;
  margin: .1rem;
  padding: 7px 7px;
}
.App .upper-menus .is-active,
.App .bubble-menus .is-active,
.App .floating-menus .is-active {
  color: #ff8e56;
}

.App .upper-menus {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.App .bubble-menus,
.App .floating-menus {
  border-radius: 6px;
}
.App .bubble-menus:not(:visible) {
  opacity: 0;
}
.App .bubble-menus:visible {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.App  .previews {
  display: flex;
  justify-content: space-between;
}

.App .previews .html-preview,
.App .previews .json-preview {
  width: 47%;
  margin-top: 2rem;
}

.App .previews .html-preview pre,
.App .previews .json-preview pre {
  background-color: #e4e4e4;
  padding: 14px;
  font-size: 15px;
}
.App .previews .html-preview pre {
  white-space: wrap;
}

/* Basic editor styles */
.tiptap {
  border-left: 1px solid #383838;
  border-right: 1px solid #383838;
  border-bottom: 1px solid #383838;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 15px;
}

.tiptap > * + * {
  margin-top: 0.75em;
}

.tiptap ul,
.tiptap ol {
  padding: 0 1.2rem;
}

.tiptap h1,
.tiptap h2,
.tiptap h3,
.tiptap h4,
.tiptap h5,
.tiptap h6 {
  line-height: 1.1;
}

.tiptap code {
  background-color: rgba(#616161, 0.1);
  color: #616161;
}

.tiptap pre {
  background: #0D0D0D;
  color: #FFF;
  font-family: 'JetBrainsMono', monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.tiptap pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.9rem;
}

.tiptap img {
  max-width: 100%;
  height: auto;
}

.tiptap blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(13, 13, 13, 0.1);
}

.tiptap hr {
  border: none;
  border-top: 2px solid rgba(13, 13, 13, 0.1);
  margin: 2rem 0;
}